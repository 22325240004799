@import url(https://fonts.googleapis.com/css?family=Assistant|Cormorant&display=swap);
@import url(https://fonts.googleapis.com/css?family=Cormorant|Crimson+Pro&display=swap);
body {
  margin: 0;
  font-family: 'Cormorant', serif;
  font-size: 20px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #151D1E;
  -webkit-transform: translate3d(0px, 0px, 0px);
          transform: translate3d(0px, 0px, 0px);

  height: 100vh;
  width: 100%;

  background-attachment: fixed;
  background-repeat: no-repeat;
}

h1 {
  margin: 0;
  font-family: 'Cormorant', serif;
  font-weight: 300;
  font-size: 200px;
  line-height: 200px;
  letter-spacing: -0.46em;
  text-indent: -0.46em;
  margin-top: 100px;
  color: #D4174C;
}

h2 {
  font-family: 'Cormorant', serif;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.2em;
  font-weight: 300;
  text-indent: 0.2em;
  color: #ffffff;
}

h3 {
  font-family: 'Cormorant', serif;
  font-size: 35px;
  line-height: 35px;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-indent: 0.1em;
  color: #ffffff;
}

h4 {
  font-family: 'Cormorant', serif;
  font-size: 28px;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-indent: 0.1em;
  color: #ffffff;
  margin-top: 75px;
  margin-bottom: 20px;
}

p {
  font-family: 'Assistant', sans-serif;
  color: #ffffff;
}


.App {
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .App {
    max-width: 320px;
  }
}

.App-header {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}



.Login {
    margin: 0;
}

.Lock-Icon {
    max-width: 75px;
    margin: 0 auto;
    padding-bottom: 50px;
}

.PassCodeForm {
    max-width: 300px;
    display: inline-block;
}

.PassCodeInput {
    width: 250px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    font-size: 20px;
    padding: 0px 15px;
    outline: none;
    margin: 0px 0px 20px;
}

.PassCodeSubmit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 280px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    font-size: 30px;
    outline: none;
    background-color: #D4174C;
    color: white;
    font-family: 'Cormorant', serif;
    text-transform: uppercase;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
    margin-bottom: 100px;
}

.PassCodeSubmit:hover {
    background-color: white;
    color: #D4174C;
    -webkit-transition-duration: 0.5s;
            transition-duration: 0.5s;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
p {
    margin: 0 auto;
    max-width: 600px;
    margin-top: 50px;
}

.nomargin {
    margin-top: 0px;
}

.Line {
    max-width: 600px;
    border: hidden;
    border-top: 1px solid black;
    margin-top: 50px;
}
.GuestList {
    margin: 0;
    padding-top: 50px;
    padding-bottom: 100px;
}
.Guest-Container {
    display: grid;
    grid-template-columns: 40px 240px 60px;
    grid-auto-flow: row;
    margin: 0 auto;
    width: 320px;
    padding-bottom: 20px;
    align-items: center;
    justify-items: center;
}

.Guest-Index {
    grid-column: 1;
}

.Guest-Name {
    grid-column: 2;
}

.not-coming {
    text-decoration: line-through;
    -webkit-text-decoration-color: #ffffff;
            text-decoration-color: #ffffff;
}

.invisible {
    display: none;
}

.Guest-Container p {
    margin: 0px auto;
}


/* ---- */
.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 2px solid whitesmoke; 
    border-radius: 20px;
    background-color: whitesmoke;
    -webkit-transition: 0.3s ease-in;
    transition: 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 20px; margin: 0px;
    background: #D4174C;
    position: absolute; top: 0; bottom: 0;
    right: 40px;
    border: 2px solid #D4174C; 
    border-radius: 20px;
    -webkit-transition: all 0.3s ease-in 0s;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: greenyellow;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked {
   border-color: greenyellow;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px; 
}
