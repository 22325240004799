@import url('https://fonts.googleapis.com/css?family=Cormorant|Crimson+Pro&display=swap');

.Login {
    margin: 0;
}

.Lock-Icon {
    max-width: 75px;
    margin: 0 auto;
    padding-bottom: 50px;
}

.PassCodeForm {
    max-width: 300px;
    display: inline-block;
}

.PassCodeInput {
    width: 250px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    font-size: 20px;
    padding: 0px 15px;
    outline: none;
    margin: 0px 0px 20px;
}

.PassCodeSubmit {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    width: 280px;
    height: 40px;
    border-radius: 20px;
    border-style: none;
    font-size: 30px;
    outline: none;
    background-color: #D4174C;
    color: white;
    font-family: 'Cormorant', serif;
    text-transform: uppercase;
    transition-duration: 0.5s;
    margin-bottom: 100px;
}

.PassCodeSubmit:hover {
    background-color: white;
    color: #D4174C;
    transition-duration: 0.5s;
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}