.Guest-Container {
    display: grid;
    grid-template-columns: 40px 240px 60px;
    grid-auto-flow: row;
    margin: 0 auto;
    width: 320px;
    padding-bottom: 20px;
    align-items: center;
    justify-items: center;
}

.Guest-Index {
    grid-column: 1;
}

.Guest-Name {
    grid-column: 2;
}

.not-coming {
    text-decoration: line-through;
    text-decoration-color: #ffffff;
}

.invisible {
    display: none;
}

.Guest-Container p {
    margin: 0px auto;
}


/* ---- */