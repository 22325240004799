@import url('https://fonts.googleapis.com/css?family=Assistant|Cormorant&display=swap');

body {
  margin: 0;
  font-family: 'Cormorant', serif;
  font-size: 20px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #151D1E;
  transform: translate3d(0px, 0px, 0px);

  height: 100vh;
  width: 100%;

  background-attachment: fixed;
  background-repeat: no-repeat;
}

h1 {
  margin: 0;
  font-family: 'Cormorant', serif;
  font-weight: 300;
  font-size: 200px;
  line-height: 200px;
  letter-spacing: -0.46em;
  text-indent: -0.46em;
  margin-top: 100px;
  color: #D4174C;
}

h2 {
  font-family: 'Cormorant', serif;
  font-size: 50px;
  line-height: 50px;
  letter-spacing: 0.2em;
  font-weight: 300;
  text-indent: 0.2em;
  color: #ffffff;
}

h3 {
  font-family: 'Cormorant', serif;
  font-size: 35px;
  line-height: 35px;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-indent: 0.1em;
  color: #ffffff;
}

h4 {
  font-family: 'Cormorant', serif;
  font-size: 28px;
  letter-spacing: 0.1em;
  font-weight: 300;
  text-indent: 0.1em;
  color: #ffffff;
  margin-top: 75px;
  margin-bottom: 20px;
}

p {
  font-family: 'Assistant', sans-serif;
  color: #ffffff;
}

