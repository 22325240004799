.App {
  text-align: center;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .App {
    max-width: 320px;
  }
}

.App-header {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


