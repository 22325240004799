.onoffswitch {
    position: relative; width: 60px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}
.onoffswitch-checkbox {
    display: none;
}
.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    height: 20px; padding: 0; line-height: 20px;
    border: 2px solid whitesmoke; 
    border-radius: 20px;
    background-color: whitesmoke;
    transition: 0.3s ease-in;
}
.onoffswitch-label:before {
    content: "";
    display: block; width: 20px; margin: 0px;
    background: #D4174C;
    position: absolute; top: 0; bottom: 0;
    right: 40px;
    border: 2px solid #D4174C; 
    border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
    background-color: greenyellow;
}
.onoffswitch-checkbox:checked + .onoffswitch-label, .onoffswitch-checkbox:checked {
   border-color: greenyellow;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
    right: 0px; 
}