p {
    margin: 0 auto;
    max-width: 600px;
    margin-top: 50px;
}

.nomargin {
    margin-top: 0px;
}

.Line {
    max-width: 600px;
    border: hidden;
    border-top: 1px solid black;
    margin-top: 50px;
}